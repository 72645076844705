import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('../pages/other-pages/login-page/login-page.component').then(
        (mod) => mod.LoginPageComponent
      ),
  },
  {
    path: 'registration',
    loadComponent: () =>
      import(
        '../pages/other-pages/registration-page/registration-page.component'
      ).then((mod) => mod.RegistrationPageComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        '../pages/other-pages/forgot-password/forgot-password.component'
      ).then((mod) => mod.ForgotPasswordComponent),
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('../pages/profile/profile-page/profile-page.component').then(
        (mod) => mod.ProfilePageComponent
      ),
  },
  {
    path: 'auth/google/callback',
    loadComponent: () =>
      import('../common/components/auth-google/auth-google.component').then(
        (mod) => mod.AuthGoogleComponent
      ),
  },
];

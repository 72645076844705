import { Routes } from '@angular/router';

export const paymentRoutes: Routes = [
  {
    path: 'payment-info-success',
    loadComponent: () =>
      import('../pages/payment-pages/thanks-page/thanks-page.component').then(
        (mod) => mod.ThanksPageComponent
      ),
  },
  {
    path: 'payment-info-error',
    loadComponent: () =>
      import('../pages/payment-pages/error-page/error-page.component').then(
        (mod) => mod.ErrorPageComponent
      ),
  }
];

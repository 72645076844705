import { Routes } from '@angular/router';

export const mainRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
          import('../pages/main-page/main-page.component').then(
            (mod) => mod.MainPageComponent
          ),
      },
    {
        path: '**',
        loadComponent: () =>
          import('../pages/main-page/main-page.component').then(
            (mod) => mod.MainPageComponent
          ),
      },
]
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { Observable, map, tap } from 'rxjs';
import { FooterData } from './footer.interface';
import { ServerResponseSingle } from '../../models/server-response.interface';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  getFooterData(languageKey: string): Observable<FooterData> {
    const url = `${this.API_URL}/footer?locale=${languageKey}&populate=deep`;
    return this.http.get<ServerResponseSingle<FooterData>>(url).pipe(map((resp) => resp.data.attributes));
  }
}

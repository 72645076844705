import { Routes } from '@angular/router';

export const webinarsRoutes: Routes = [
  {
    path: 'webinars',
    loadComponent: () =>
      import('../pages/webinars/webinars-page/webinars-page.component').then(
        (mod) => mod.WebinarsPageComponent
      ),
  },
  {
    path: 'webinars/:slug',
    loadComponent: () =>
      import('../pages/webinars/webinar-details/webinar-details.component').then(
        (mod) => mod.WebinarDetailsComponent
      ),
  },
];

import { Routes } from '@angular/router';

export const policyRoutes: Routes = [
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('../pages/other-pages/privacy-policy/privacy-policy.component').then(
        (mod) => mod.PrivacyPolicyComponent
      ),
  },
  {
    path: 'refund-policy',
    loadComponent: () =>
      import('../pages/other-pages/refund-policy/refund-policy.component').then(
        (mod) => mod.TermsOfServiceComponent
      ),
  },
];

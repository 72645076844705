import { Routes } from '@angular/router';

export const contactUsRoutes: Routes = [
    {
        path: 'contact',
        loadComponent: () =>
          import('../pages/other-pages/contact-us/contact-us.component').then(
            (mod) => mod.ContactUsComponent
          ),
      },
]
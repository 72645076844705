import { Routes } from '@angular/router';

export const interviwRoutes: Routes = [
  {
    path: 'test-interview',
    loadComponent: () =>
      import('../pages/other-pages/interview-page/interview-page.component').then(
        (mod) => mod.InterviewPageComponent
      ),
  },
];

<footer class="footer-area" *ngIf="footerData | async as footerData">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img class="main-logo" [src]="serverUrl + footerData.logo.data.attributes.url" alt="logo">
                    </a>
                    <p>{{footerData.desc}}</p>
                    <ul class="social-link">
                        <li *ngFor="let item of footerData.socials">
                            <a href="{{item.link}}" class="d-block" target="_blank">
                                <i class='{{item.icon}}'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6" *ngFor="let content of footerData.widgets.slice(0, 2)">
                <div class="single-footer-widget">
                    <h3>{{content.title}}</h3>
                    <ul class="footer-links-list">
                        <li *ngFor="let item of content.allItems">
                            <a routerLink="{{item.link}}">
                                {{item.linkText}}
                            </a>
                        </li>
                        <li (click)="openTermsOfServicePDF()">
                            <a translate>
                                footer.terms-of-services
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{footerData.title}}</h3>
                    <ul class="footer-contact-info">
                        <!-- <li><i class='bx bx-map'></i> {{footerData.location}}</li> -->
                        <li><i class='bx bx-phone-call'></i> <a href="tel:{{footerData.phoneNumber}}">{{footerData.phoneNumber}}</a></li>
                        <li><i class='bx bx-envelope'></i> <a href="mailto:{{footerData.email}}">{{footerData.email}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="copyright-content">
                {{footerData.copyright}}
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
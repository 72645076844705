import { Routes } from '@angular/router';

export const articlesRoutes: Routes = [
    {
        path: 'articles',
        loadComponent: () =>
          import('../pages/articles/articles-page/articles-page.component').then(
            (mod) => mod.ArticlesPageComponent
          ),
      },
      {
        path: 'article/:slug',
        loadComponent: () =>
          import('../pages/articles/article-details/article-details.component').then(
            (mod) => mod.ArticleDetailsComponent
          ),
      },
]
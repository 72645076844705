import { Routes } from '@angular/router';
import { MethodViewComponent } from '../pages/handbooks/handbook-details/components/method-view/method-view.component';
import { HandbookDetailsBannerComponent } from '../pages/handbooks/handbook-details/components/handbook-details-banner/handbook-details-banner.component';

export const handbookRoutes: Routes = [
  {
    path: 'handbook',
    loadComponent: () =>
      import('../pages/handbooks/handbook/handbook.component').then(
        (mod) => mod.HandbookComponent
      ),
  },
  {
    path: 'handbook/:slug',
    loadComponent: () =>
      import('../pages/handbooks/handbook-details/handbook-details.component').then(
        (mod) => mod.HandbookDetailsComponent
      ),
    children: [
      {
        path: ':method',
        component: MethodViewComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        component: HandbookDetailsBannerComponent
      }
    ],
  },
];

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable, map, tap } from 'rxjs';
import { Navbar } from '../interfaces/navbar.interface';
import { ServerResponseSingle } from 'src/app/common/models/server-response.interface';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private API_URL = environment.API_URL;
  locale: string = 'en'
  
  constructor(private http: HttpClient) {}

  getNavbarData(languageKey: string): Observable<Navbar> {
    const url = `${this.API_URL}/navbar?locale=${languageKey}&populate=deep`;
    return this.http.get<ServerResponseSingle<Navbar>>(url).pipe(map(resp=> resp.data.attributes));
  }
}

import { Injectable } from '@angular/core';
import { SsrBrowserService } from './ssr-browser.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  isBrowserMode: boolean;

  constructor(private ssrBrowserService: SsrBrowserService) {
    this.isBrowserMode = this.ssrBrowserService.getIsBrowserMode();
  }

  setJwtToken(token: string): void {
    if (this.isBrowserMode) {
      localStorage.setItem('jse-data-token', token);
    }
  }

  removeJwtToken(): void {
    if (this.isBrowserMode) {
      localStorage.removeItem('jse-data-token');
    }
  }

  getJwtToken(): string | null {
    if (this.isBrowserMode) {
      return localStorage.getItem('jse-data-token');
    }
  }

  setAppLanguage(language: string): void {
    if (this.isBrowserMode) {
      localStorage.setItem('jse-language-settings', language);
    }
  }

  getAppLanguage(): string | null {
    if (this.isBrowserMode) {
      return localStorage.getItem('jse-language-settings');
    }
  }

  setTokenLiveTime(): void {
    const currentTimeMillis = Date.now();
    const expirationTimeMillis = currentTimeMillis + 1 * 60 * 60 * 1000;
    if (this.isBrowserMode) {
      localStorage.setItem(
        'jse-token-live-time',
        JSON.stringify(expirationTimeMillis)
      );
    }
  }

  removeTokenLiveTime(): void {
    localStorage.removeItem('jse-token-live-time');
  }

  isTokenExpired(): boolean {
    if (this.isBrowserMode) {
      const currentTimeMillis = Date.now();
      const tokenLiveTimeFromLocal = JSON.parse(
        localStorage.getItem('jse-token-live-time')
      );
      return currentTimeMillis > tokenLiveTimeFromLocal;
    }
  }
}

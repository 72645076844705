import { Routes } from '@angular/router';

export const buyNowRoutes: Routes = [
    {
        path: 'buy-subscription',
        loadComponent: () =>
          import('../pages/other-pages/buy-sub/buy-sub.component').then(
            (mod) => mod.BuySubComponent
          ),
      },

]
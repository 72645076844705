import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { HandbookService } from './../../../../../common/services/handbook.service';
import { Observable, Subscription, of, switchMap } from 'rxjs';
import { ErrorHandlerService } from 'src/app/common/services/error-handler.service';
import { LoaderService } from 'src/app/common/services/loader.service';
import { TranslationService } from 'src/app/common/services/translation.service';
import { LOCALE_CODES } from 'src/app/common/helpers/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { HighlightJsDirective, HighlightJsModule } from 'ngx-highlight-js';
import { HandbookModel } from '../../handbook-details.interface';

@Component({
  selector: 'jse-method-view',
  standalone: true,
  imports: [CommonModule, RouterLink, MarkdownModule, HighlightJsModule, HighlightJsDirective],
  templateUrl: './method-view.component.html',
  styleUrls: ['./method-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MethodViewComponent implements OnInit, OnDestroy {
  method: Observable<HandbookModel>;
  translationSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private handbookService: HandbookService,
    private errorHandlerService: ErrorHandlerService,
    private loaderService: LoaderService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoading();
    this.translationSub = this.translationService
      .getCurrentLanguage()
      .pipe(
        switchMap((languageKey: string) =>
          this.route.params.pipe(
            switchMap((param: Params) =>
              of(
                this.handbookService.getOneHandbookBySlug(
                  param.method,
                  LOCALE_CODES[languageKey]
                )
              )
            )
          )
        )
      )
      .subscribe(
        (method: Observable<HandbookModel>) => {
          this.method = method;
          this.cdr.markForCheck();
          this.loaderService.hideLoading();
        },
        (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
          this.loaderService.hideLoading();
        }
      );
  }

  ngOnDestroy(): void {
    this.translationSub?.unsubscribe();
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Router, RouterLink } from '@angular/router';
import { NavbarService } from './components/services/navbar.service';
import { CoursesService } from 'src/app/common/services/courses.service';
import { CommonModule } from '@angular/common';
import { Navbar } from './components/interfaces/navbar.interface';
import { Course } from '../../models/course.interface';
import {
  Observable,
  Subscription,
  forkJoin,
  switchMap,
} from 'rxjs';
import { FilterPipe } from './components/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { TranslationService } from './../../services/translation.service';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.interface';
import { LOCALE_CODES } from '../../helpers/constants';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'jse-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],

  imports: [
    CommonModule,
    RouterLink,
    FilterPipe,
    FormsModule,
    DropdownModule,
    TranslateModule,    
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  searchValue: string = '';
  navbarData: Navbar;
  courses: Course[];
  currentUser: Observable<User>;
  classApplied: boolean = false;
  classSearchApplied: boolean = false;
  translateSub: Subscription;
  currentLanguage: string;
  serverUrl: string = environment.API_URL_UPLOADS

  countriesForLocales = [
    { language: 'UA', code: 'ua' },
    { language: 'EN', code: 'en' },
    { language: 'RU', code: 'ru' },
  ];

  constructor(
    private notifier: NotifierService,
    private coursesService: CoursesService,
    private navbarService: NavbarService,
    public router: Router,
    private cdRef: ChangeDetectorRef,
    private translationService: TranslationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getStudent()
    this.translateSub = this.translationService.getCurrentLanguage().pipe(
      switchMap(languageKey => forkJoin([
        this.coursesService.getCourses(LOCALE_CODES[languageKey]),        
        this.navbarService.getNavbarData(LOCALE_CODES[languageKey]),        
        this.currentLanguage = languageKey,
      ]))
    ).subscribe(([courses, navbarData]) => {
      this.courses = courses;   
      this.navbarData = navbarData;      
    },
    (error: Error) => this.notifier.notify('error', error.message));
  }

  toggleClass(): void {
    this.classApplied = !this.classApplied;
  }

  togglSearcheClass(): void {
    this.classSearchApplied = !this.classSearchApplied;
  }

  logOutUser(): void {
    this.router.navigate(['/']);
    this.authService.logout();
    this.notifier.notify('success', 'Logout successful.');
  }

  switchLanguage(event: any): void {
    const languageCode = typeof event === 'string' ? event : event.value.code;
    this.translationService.setLanguage(languageCode);
  }

  getFlagImageUrl(languageCode: string): string {
    return `../../../../assets/img/${languageCode}-flag.svg`;
  }

  isAuthentificated(): boolean {
    return this.authService.isAuthenticated();
  }

  ngOnDestroy(): void {
    this.translateSub?.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Observable,
  tap,
  BehaviorSubject,
  map,
  mergeMap,
  take,
  switchMap,
  of,
  catchError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { User, UserLoginData } from '../models/user.interface';
import { Auth } from '../models/auth.interface';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private API_URL = environment.API_URL;
  token: string;
  student: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  login(candidate: UserLoginData): Observable<User> {
    const url = `${this.API_URL}/auth/local`;
    return this.http.post<Auth>(url, candidate).pipe(
      mergeMap((resp) => {
        this.token = resp.jwt;
        this.localStorageService.setJwtToken(resp.jwt);
        this.localStorageService.setTokenLiveTime();
        return this.getUserByToken();
      }),
      tap((student) => this.setStudent(student))
    );
  }

  // getUserByToken(): Observable<User> {
  //   const url = `${this.API_URL}/users/me?populate=deep`;
  //   return this.http.get<User>(url).pipe(
  //     map((user) => {
  //       if (this.isSubValid(user.subFinishDate)) {
  //         return user;
  //       } else {
  //         this.updateStudent(user.id, {
  //           isHasSubscription: false,
  //           subscriptionType: null,
  //         }).subscribe();
  //         return { ...user, isHasSubscription: false };
  //       }
  //     })
  //   );
  // }

  getUserByToken(): Observable<User> {
    const url = `${this.API_URL}/users/me?populate=deep`;

    return this.http.get<User>(url).pipe(
      switchMap((user) => {
        if (this.isSubValid(user.subFinishDate) || !user.isHasSubscription) {
          return of(user);
        } else {
          return this.updateStudent(user.id, {
            isHasSubscription: false,
            subscriptionType: null,
          }).pipe(map(() => ({ ...user, isHasSubscription: false })));
        }
      })
    );
  }

  isSubValid(subDate: Date | string): boolean {
    const currentDate = new Date();
    const comparisonDate = new Date(subDate);
    return comparisonDate > currentDate;
  }

  googleAuth(accessToken: string): Observable<any> {
    const url = `${this.API_URL}/auth/google/callback?access_token=${accessToken}`;
    return this.http.get(url);
  }

  refreshUserInfo() {
    this.token = this.token
      ? this.token
      : this.localStorageService.getJwtToken();
    this.getUserByToken()
      .pipe(take(1))
      .subscribe((student) => {
        this.setStudent(student);
      });
  }

  logout(): void {
    this.token = null;
    this.student.next(null);
    this.localStorageService.removeJwtToken();
    this.localStorageService.removeTokenLiveTime();
  }

  registration(candidate: any): Observable<any> {
    const url = `${this.API_URL}/auth/local/register`;
    const { username, email, password, fullName, phoneNumber } = candidate;
    return this.http.post<any>(url, {
      username,
      email,
      password,
      fullName,
      phoneNumber,
    });
  }

  resetPasswordEmailSend(candidate: any): Observable<any> {
    const url = `${this.API_URL}/auth/forgot-password`;
    return this.http.post(url, candidate);
  }

  resetPasswordSetNewPassword(candidate: any): Observable<any> {
    const url = `${this.API_URL}/auth/reset-password`;
    return this.http.post(url, candidate);
  }

  changeUserPassword(candidate: any): Observable<any> {
    const url = `${this.API_URL}/auth/change-password`;
    return this.http.post(url, candidate);
  }

  isAuthenticated(): boolean {
    return !!this.token;
  }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  setStudent(user: User): void {
    this.student.next(user);
  }

  getStudent(): BehaviorSubject<User> {
    return this.student;
  }

  updateStudent(
    studentId: number,
    updatedUser: Partial<User>
  ): Observable<User> {
    const url = `${this.API_URL}/users/${studentId}?populate=deep`;
    return this.http.put<User>(url, updatedUser);
  }

  addCourseToStudent(slug: string): Observable<User> {
    const currentStudent = this.getCurrentStudent();
    const url = `${this.API_URL}/users/${currentStudent.id}`;
    const updatedStudent = {
      assignedCourses: [...currentStudent.assignedCourses, { courseId: slug }],
    };
    return this.http.put<User>(url, updatedStudent);
  }

  markTaskAsPassed(taskId, lessonId, courseId, quizResult?): Observable<User> {
    const currentStudent = this.getCurrentStudent();
    const assignedCourses = currentStudent.assignedCourses;
    const url = `${this.API_URL}/users/${currentStudent.id}`;
    let complitedTask: any = { lessonId, taskId };
    if (quizResult) {
      complitedTask.quizResult = quizResult;
    }
    assignedCourses.map((c) => {
      if (c.courseId === courseId) {
        const passingProgress = c.passingProgress;
        const isTaskPassed = passingProgress.some(
          (lesson) => lesson.taskId === taskId && lesson.lessonId === lessonId
        );
        if (!isTaskPassed) {
          c.passingProgress.push(complitedTask);
        } else if (isTaskPassed && quizResult) {
          c.passingProgress = c.passingProgress.map((task) => {
            if (task.taskId === taskId) {
              task.quizResult = quizResult;
            }
            return task;
          });
        }
      }
    });
    const updatedStudent = {
      assignedCourses,
    };
    return this.http.put<User>(url, updatedStudent);
  }

  planUnsubscribe(userId: any): Observable<any> {
    const url = `${this.API_URL}/fondy-unsubscribe`;
    return this.http.post(url, userId);
  }

  getCurrentStudent(): User {
    return this.student.getValue();
  }
}

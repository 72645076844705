import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { FooterService } from './footer.service';
import { CommonModule } from '@angular/common';
import { FooterData } from './footer.interface';
import { Observable, Subscription, of, switchMap } from 'rxjs';
import { RouterLink } from '@angular/router';
import { openTermsOfServicePDF } from '../../helpers/open-terms-of-service';
import { TranslationService } from './../../services/translation.service';
import { LOCALE_CODES } from '../../helpers/constants';
import { TranslateModule } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'jse-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink, TranslateModule],
})
export class FooterComponent implements OnInit, OnDestroy {
  footerData: Observable<FooterData>;
  translationSub: Subscription;
  serverUrl: string = environment.API_URL_UPLOADS

  constructor(
    private footerService: FooterService,
    private translationService: TranslationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.translationSub = this.translationService
      .getCurrentLanguage()
      .pipe(
        switchMap((languageKey: string) => {
          return of(
            this.footerService.getFooterData(LOCALE_CODES[languageKey])
          );
        })
      )
      .subscribe(
        (footerData) => (this.footerData = footerData),
        (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        }
      );
  }

  openTermsOfServicePDF(): void {
    openTermsOfServicePDF();
  }

  ngOnDestroy(): void {
    this.translationSub?.unsubscribe();
  }
}

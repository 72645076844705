<div
  class="navbar-area"
  *ngIf="navbarData"
>
  <div class="container-fluid">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      [class.active]="classApplied"
    >
      <a class="navbar-brand" routerLink="/">
        <img class="main-logo" [src]="serverUrl + navbarData.logo.data.attributes.url" alt="logo" />
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="search-box" [class.active]="classSearchApplied">
          <input
            type="text"
            [(ngModel)]="searchValue"
            class="input-search"
            [placeholder]="'navbar.search.placeholder' | translate"
            (click)="togglSearcheClass()"
          />
          <button type="submit">
            <i class="flaticon-search"></i>
          </button>
          <div class="courses-lists" *ngIf="courses">
            <div
              class="list-group-item"
              *ngFor="let content of courses | filter : searchValue"
            >
              <div class="row align-items-center" (click)="togglSearcheClass()">
                <div class="col-lg-3 col-md-2 col-3">
                  <a
                    [routerLink]="['/course', content.slug]"
                    class="course-img"
                  >
                    <img [src]="serverUrl + content.image.data.attributes.url" alt="image" />
                  </a>
                </div>
                <div class="col-lg-9 col-md-10 col-9">
                  <div class="info">
                    <h3>
                      <a [routerLink]="['/course', content.slug]">
                        {{ content.title }}
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let item of navbarData.menuLists">
            <a
              routerLink="{{ item.menuLink }}"
              [class]="
                item.dropdowns?.length > 0
                  ? 'nav-link dropdown-toggle'
                  : 'nav-link'
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ item.menuName }}
            </a>
            <ng-container *ngIf="item.dropdowns?.length > 0">
              <ul class="dropdown-menu">
                <li
                  class="nav-item"
                  *ngFor="let dropdownItem of item.dropdowns"
                >
                  <a
                    routerLink="{{ dropdownItem.menuLink }}"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    {{ dropdownItem.menuName }}
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-toggle">{{
              currentLanguage | uppercase
            }}</a>
            <ng-container>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    class="flag-link nav-link link"
                    (click)="switchLanguage('en')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/en-flag.svg"
                      alt="en-flag"
                    />
                    <span translate> navbar.lang-picker.english </span>
                  </a>
                  <a
                    class="nav-link link flag-link"
                    (click)="switchLanguage('ua')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/ua-flag.svg"
                      alt="ua-flag"
                    />
                    <span translate> navbar.lang-picker.ukrainian </span>
                  </a>
                  <a
                    class="nav-link link flag-link"
                    (click)="switchLanguage('ru')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/ru-flag.svg"
                      alt="ru-flag"
                    />
                    <span translate> navbar.lang-picker.russian </span>
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>

        </ul>
        <ul class="navbar-nav for-responsive">
          <li class="nav-item" *ngFor="let item of navbarData.menuLists">
            <a
              routerLink="{{ item.menuLink }}"
              [class]="
                item.dropdowns?.length > 0
                  ? 'nav-link dropdown-toggle'
                  : 'nav-link'
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="toggleClass()"
            >
              {{ item.menuName }}
            </a>
            <ng-container *ngIf="item.dropdowns?.length > 0">
              <ul class="dropdown-menu">
                <li
                  class="nav-item"
                  *ngFor="let dropdownItem of item.dropdowns"
                >
                  <a
                    routerLink="{{ dropdownItem.menuLink }}"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                    (click)="toggleClass()"
                  >
                    {{ dropdownItem.menuName }}
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-toggle">{{
              currentLanguage | uppercase
            }}</a>
            <ng-container>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    class="flag-link nav-link link"
                    (click)="switchLanguage('en')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/en-flag.svg"
                      alt="en-flag"
                    />
                    <span translate> navbar.lang-picker.english </span>
                  </a>
                  <a
                    class="nav-link link flag-link"
                    (click)="switchLanguage('ua')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/ua-flag.svg"
                      alt="ua-flag"
                    />
                    <span translate> navbar.lang-picker.ukrainian </span>
                  </a>
                  <a
                    class="nav-link link flag-link"
                    (click)="switchLanguage('ru')"
                  >
                    <img
                      class="flag-icon"
                      src="../../../../assets/img/ru-flag.svg"
                      alt="ru-flag"
                    />
                    <span translate> navbar.lang-picker.russian </span>
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="others-option d-flex align-items-center">
        <!-- <div class="option-item modile-languages-picker">
          <p-dropdown
            [options]="countriesForLocales"
            (onChange)="switchLanguage($event)"
            optionLabel="language"
          >
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2 flag-wrapper">
                <img
                  [src]="getFlagImageUrl(currentLanguage)"
                  style="width: 25px"
                />
              </div>
            </ng-template>
          </p-dropdown>
        </div> -->
        <div class="option-item"  *ngIf="currentUser | async">
          <a routerLink="/profile" class="profile-btn" >
            <i class="flaticon-user"></i>
            <span></span>
          </a>
        </div>
        <!-- <div class="option-item">
          <div class="cart-btn">
            <a routerLink="/courses-cart">
              <i class="bx bx-book-add"></i>
              <span>{{ cartCourses.length }}</span>
            </a>
          </div>
        </div>
        <div class="option-item" *ngIf="cartProducts.length">
          <div class="cart-btn">
            <a routerLink="/cart">
              <i class="flaticon-shopping-cart"></i>
              <span>{{ cartProducts.length }}</span>
            </a>
          </div>
        </div> -->
        <div class="option-item" *ngIf="!(currentUser | async)">
          <a routerLink="/login" class="default-btn login-btn">
            <i class="flaticon-user icon-desktop"></i>
            <i class="pi pi-sign-in icon-mobile"></i>
            <b translate class="button-login-out">navbar.buttons.login</b>
            <span></span>
          </a>
        </div>
        <div class="option-item" *ngIf="currentUser | async">
          <a (click)="logOutUser()" class="default-btn">
            <i class="flaticon-user icon-desktop"></i>
            <i class="pi pi-sign-out icon-mobile"></i>
            <b translate class="button-login-out">navbar.buttons.logout</b>          
          </a>
        </div>        
      </div>
    </nav>
  </div>
</div>

<div class="nav-bar-box"></div>
import { Routes } from '@angular/router';

export const aboutUsRoutes: Routes = [
    {
        path: 'about/author',
        loadComponent: () =>
          import('../pages/about-us/about-page/about-page.component').then(
            (mod) => mod.AboutPageComponent
          ),
      },
      {
        path: 'about/platform',
        loadComponent: () =>
          import('../pages/about-us/about-platform-page/about-platform-page.component').then(
            (mod) => mod.AboutPlatformPageComponent
          ),
      },
]
import { Routes } from '@angular/router';
import { AuthGuard } from '../common/services/auth.guard';

export const coursesRoutes: Routes = [
  {
    path: 'courses',
    loadComponent: () =>
      import('../pages/courses/courses-page/courses-page.component').then(
        (mod) => mod.CoursesPageComponent
      ),
  },
  {
    path: 'course/:slug',
    loadComponent: () =>
      import('../pages/courses/course-details/course-details.component').then(
        (mod) => mod.CourseDetailsComponent
      ),
  },
  {
    path: 'passing-course/:slug',
    loadComponent: () =>
      import('../pages/courses/passing-course/passing-course.component').then(
        (mod) => mod.PassingCourseComponent
      ),
      canActivate: [AuthGuard]
  },
];

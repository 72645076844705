import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Course } from '../models/course.interface';
import {
  ServerResponseMulti,
  ServerResponseSingle,
} from '../models/server-response.interface';
import { Quiz } from 'src/app/pages/quiz/components/quiz.interface';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  private API_URL = environment.API_URL;
  courses$: BehaviorSubject<Course[]> = new BehaviorSubject<Course[]>(null);
  currentLanguageCode: string = 'en';

  constructor(private http: HttpClient) {}

  fetchCourses(languageKey?: string): Observable<Course[]> {
    const url = `${this.API_URL}/courses?sort=id:DESC&locale=${languageKey}&populate=deep`;
    return this.http.get<ServerResponseMulti<Course>>(url).pipe(
      map((resp) => {
        const courses = resp.data.map((data) => data.attributes);
        this.currentLanguageCode = languageKey;
        this.courses$.next(courses);
        return courses;
      })
    );
  }

  getCourses(languageKey?: string): Observable<Course[]> {
    return this.courses$.getValue() && this.currentLanguageCode === languageKey
      ? this.courses$
      : this.fetchCourses(languageKey);
  }

  getCoursesSubject(): BehaviorSubject<Course[]> {
    return this.courses$;
  }

  getQuizBySlug(slug, languageKey): Observable<Quiz> {
    const url = `${this.API_URL}/quizzes/${slug}?locale=${languageKey}`;
    return this.http.get<ServerResponseSingle<Quiz>>(url).pipe(map((resp) => resp.data.attributes));
  }

  getOneCourseBySlug(slug: string, languageKey?: string): Observable<Course> {
    const url = `${this.API_URL}/courses/${slug}?locale=${languageKey}`;
    return this.http
      .get<ServerResponseSingle<Course>>(url)
      .pipe(map((resp) => resp.data.attributes));
  }
}

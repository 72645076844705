import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'jse-handbook-details-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './handbook-details-banner.component.html',
  styleUrls: ['./handbook-details-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandbookDetailsBannerComponent implements OnInit {
  constructor(){}
  ngOnInit(): void {
  }

}

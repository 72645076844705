import { Pipe, PipeTransform } from '@angular/core';
import { Course } from 'src/app/common/models/course.interface';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(courses: Course[], searchValue: string): Course[] {
    if (!searchValue.trim()) {
      return courses;
    }
    return courses.filter((course: Course) =>
      course.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
}

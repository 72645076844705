import { Routes } from '@angular/router';

export const blogRoutes: Routes = [
    {
        path: 'blog',
        loadComponent: () =>
          import('../pages/blog/blogs-page/blogs-page.component').then(
            (mod) => mod.BlogsPageComponent
          ),
      },
      {
        path: 'blog/:slug',
        loadComponent: () =>
          import('../pages/blog/blog-details/blog-details.component').then(
            (mod) => mod.BlogDetailsComponent
          ),
      },
]
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../services/loader.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'jse-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [CommonModule],
})
export class LoaderComponent implements OnInit {
  isShowLoading: BehaviorSubject<boolean>;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.isShowLoading = this.loaderService.isLoading$;
  }
}

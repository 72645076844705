import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  filter,
  find,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { HandbookModel } from 'src/app/pages/handbooks/handbook-details/handbook-details.interface';
import { HandbookData } from 'src/app/pages/handbooks/handbook/components/handbook.interface';
import { HandbookListData } from 'src/app/pages/handbooks/handbook/components/handbooks-list/handbook-list.interface';
import { environment } from 'src/environments/environment';
import { ServerResponseMulti, ServerResponseSingle } from '../models/server-response.interface';

@Injectable({
  providedIn: 'root',
})
export class HandbookService {
  private API_URL = environment.API_URL;
  handbooks$: BehaviorSubject<HandbookModel[]> = new BehaviorSubject(null);
  currentLanguageCode: string = 'en';

  constructor(private http: HttpClient) {}

  getHandbookData(languageKey: string): Observable<HandbookData> {
    return this.http.get<ServerResponseSingle<HandbookData>>(
      `${this.API_URL}/handbook?locale=${languageKey}&populate=deep`
    ).pipe(map(resp=>resp.data.attributes));
  }

  getHandbookTypes(languageKey: string): Observable<HandbookListData> {
    return this.http.get<ServerResponseSingle<HandbookListData>>(
      `${this.API_URL}/handbook-type?locale=${languageKey}&populate=deep`
    ).pipe(map(resp=>resp.data.attributes));
  }

  getHandbooks(languageKey: string): Observable<HandbookModel[]> {
    if (
      this.handbooks$.getValue() &&
      this.currentLanguageCode === languageKey
    ) {
      return this.handbooks$;
    }
    return this.http
      .get<ServerResponseMulti<HandbookModel>>(
        `${this.API_URL}/handbook-methods?locale=${languageKey}&populate=deep`
      )
      .pipe(
        map(resp=>{
          const handbookMethods = resp.data.map((data) => data.attributes);
          this.handbooks$.next(handbookMethods);
          this.currentLanguageCode = languageKey;
          return handbookMethods
        })
      );
  }

  getOneHandbookBySlug(
    slug: string,
    languageKey: string
  ): Observable<HandbookModel> {
    if (
      this.handbooks$.getValue() &&
      this.currentLanguageCode === languageKey
    ) {
      return this.handbooks$.pipe(
        switchMap((handbook) => handbook),
        find((handbook: HandbookModel) => {
          return handbook.slug.toLowerCase() === slug.toLowerCase();
        })
      );
    }
    return this.http.get<HandbookModel>(
      `${this.API_URL}/handbook-methods?slug=${slug}`
    );
  }
}

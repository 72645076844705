import { Routes } from '@angular/router';
import { AuthGuard } from '../common/services/auth.guard';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    loadComponent: () =>
      import('../pages/profile/profile-page/profile-page.component').then(
        (mod) => mod.ProfilePageComponent
      ),
      canActivate: [AuthGuard]
  },
  {
    path: 'update-profile',
    loadComponent: () =>
      import('../pages/profile/profile-update-page/profile-update-page.component').then(
        (mod) => mod.ProfileUpdateComponent
      ),
    canActivate: [AuthGuard]
  },
];

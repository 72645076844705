<div class="app-wrapper">
  <jse-navbar></jse-navbar>
  <div class="router-outlet-wrapper">
    <router-outlet></router-outlet>
    <jse-loader></jse-loader>
  </div>
  <jse-footer></jse-footer>
  <ngx-scrolltop></ngx-scrolltop>
  <notifier-container></notifier-container>
</div>

import { Routes } from '@angular/router';

export const galleryRoutes: Routes = [
  {
    path: 'gallery',
    loadComponent: () =>
      import('../pages/other-pages/gallery-page/gallery-page.component').then(
        (mod) => mod.GalleryPageComponent
      ),
  },
];

import { Routes } from '@angular/router';
import { aboutUsRoutes } from './app-routes/about-routes';
import { articlesRoutes } from './app-routes/articles-routes';
import { authRoutes } from './app-routes/auth.routes';
import { blogRoutes } from './app-routes/blog-routes';
import { contactUsRoutes } from './app-routes/contact-us.routes';
import { faqRoutes } from './app-routes/faq.routes';
import { galleryRoutes } from './app-routes/gallery.routes';
import { interviwRoutes } from './app-routes/interview.routes';
import { policyRoutes } from './app-routes/policy.routes';
import { webinarsRoutes } from './app-routes/webinars.routes';
import { coursesRoutes } from './app-routes/courses.routes';
import { mainRoutes } from './app-routes/main.routes';
import { handbookRoutes } from './app-routes/handbook-routes';
import { profileRoutes } from './app-routes/profile-routes';
import { buyNowRoutes } from './app-routes/buy-now.routes';
import { paymentRoutes } from './app-routes/thanks-page-router';

export const routes: Routes = [
  ...aboutUsRoutes,
  ...articlesRoutes,
  ...authRoutes,
  ...profileRoutes,
  ...blogRoutes,
  ...contactUsRoutes,
  ...faqRoutes,
  ...galleryRoutes,
  ...interviwRoutes,
  ...policyRoutes,
  ...webinarsRoutes,
  ...coursesRoutes,
  ...handbookRoutes,
  ...buyNowRoutes,
  ...paymentRoutes,
  ...mainRoutes,
];

import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationCancel,
  NavigationEnd,
  RouterOutlet,
} from '@angular/router';
import {
  CommonModule,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { NavbarComponent } from './common/components/navbar/navbar.component';
import { FooterComponent } from './common/components/footer/footer.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NotifierModule } from 'angular-notifier';
import { LoaderComponent } from './common/components/loader/loader.component';
import { TranslationService } from './common/services/translation.service';
import { AuthService } from './common/services/auth.service';
import { SsrBrowserService } from './common/services/ssr-browser.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from './common/services/local-storage.service';

declare let $: any;

@Component({
  standalone: true,
  selector: 'jse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  imports: [
  CommonModule,
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    NotifierModule,
    NgxScrollTopModule,
    LoaderComponent,
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: Subscription;
  isBrowserMode: boolean;

  constructor(
    private router: Router,
    private translationService: TranslationService,
    private authService: AuthService,
    private ssrBrowserService: SsrBrowserService,
    private localStorageService: LocalStorageService
  ) {
    this.isBrowserMode = this.ssrBrowserService.getIsBrowserMode();
  }

  ngOnInit() {
    this.translationService.initAppLanguage();
    if (this.isBrowserMode) {
      this.recallJsFuntions();
      this.checkOnCurrentUser();
    }
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  checkOnCurrentUser() {
    if (this.localStorageService.isTokenExpired()) {
      this.localStorageService.removeJwtToken();
      this.localStorageService.removeTokenLiveTime();
      return;
    }
    const currentUserData = this.localStorageService.getJwtToken();
    if (!!currentUserData) {
      this.authService.refreshUserInfo();
    }
    return;
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  currentLanguage$: BehaviorSubject<string> = new BehaviorSubject('en');

  constructor(
    private translation: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  initAppLanguage(): void {
    const savedLanguage = this.localStorageService.getAppLanguage();
    let browserLang: string;
    if (!!savedLanguage) {
      browserLang = savedLanguage;
    } else {
      const browserLanguage = this.translation.getBrowserLang();
      browserLang = this.avaliableLanguage(browserLanguage);
    }
    this.currentLanguage$.next(browserLang || 'en');
    this.translation.use(this.currentLanguage$.getValue());
  }

  getCurrentLanguage(): BehaviorSubject<string> {
    return this.currentLanguage$;
  }

  avaliableLanguage(languageKey: string): string {
    if (languageKey !== 'uk' && languageKey !== 'en' && languageKey !== 'ru') {
      return 'en';
    }
    if (languageKey === 'uk') {
      return 'ua';
    }
    return languageKey;
  }

  setLanguage(language: string): void {
    this.currentLanguage$.next(language);
    this.translation.use(language);
    this.localStorageService.setAppLanguage(language);
  }
}

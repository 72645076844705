import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SsrBrowserService {
  isBrowserMode: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowserMode = isPlatformBrowser(platformId);
  }

  getIsBrowserMode(): boolean {
    return this.isBrowserMode;
  }
}
